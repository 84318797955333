const COLORS = {
  RED: { DEFAULT: "#BF616A", DARK: "#8c454c", DARKEST: "#5e2e33" },
  ORANGE: { DEFAULT: "#D08770", DARK: "#ab6d5b", DARKEST: "#805244" },
  YELLOW: { DEFAULT: "#EBCB8B", DARK: "#b39a68", DARKEST: "#8a7650" },
  GREEN: { DEFAULT: "#A3BE8C", DARK: "#788c66", DARKEST: "#556349" },
  TEAL: { DEFAULT: "#87C1D1", DARK: "#6794a1", DARKEST: "#4d707a" },
  BLUE: { DEFAULT: "#9B91D8", DARK: "#766fa6", DARKEST: "#56517a" },
  VIOLET: { DEFAULT: "#BF89B9", DARK: "#8f658a", DARKEST: "#634660" },
  PINK: { DEFAULT: "#EA85A8", DARK: "#ad617c", DARKEST: "#82495d" },
  WHITE: { DEFAULT: "#ECEFF4", DARK: "#d8dee9", BRIGHT: "#FFFFFF" },
  BACKGROUND: {
    LIGHT: "#434c5e",
    DARK: "#3B4252",
    BLUE: "#5e81ac",
    BLACK: "#2e3440",
  },
};

export default COLORS;
